import React from 'react';
import numbro from 'numbro';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { CSVLink } from 'react-csv';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import useWindowSize from '../../../../../../../../hooks/useWindowSize';

import styles from './DashboardDialogTransitionContent.module.scss';

function formatValue(value) {
  if (!value) { return '-'; }

  return numbro(value).format({
    thousandSeparated: true,
    mantissa: 2
  });
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  printButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  table: {
    '& thead td': {
      borderBottom: 'none',
    },
    '& th': {
      fontWeight: 400,
      backgroundColor: '#fafafa'
    }
  }
}));

export default function TransitionMatrixTable({
  fromYear,
  toYear,
  nodes,
  links,
}) {
  const classes = useStyles();
  const windowSize = useWindowSize();

  const sourceCategories = _.sortBy(_.uniq(_.map(links, 'source')));
  const targetCategories = _.sortBy(_.uniq(_.map(links, 'target')));

  const renderDownloadTableLink = () => {
    if (_.isEmpty(links)) {
      return null;
    }

    let headers = [
      { key: 'fromCategory', label: '' },
    ];

    _.each(targetCategories, (targetCategory) => {
      const categoryData = _.find(nodes, { id: targetCategory });

      headers.push({
        key: _.kebabCase(`${ _.get(categoryData, 'label') } (${ toYear })`),
        label: `${ _.get(categoryData, 'label') } (${ toYear })`
      });
    });

    headers.push({
      key: 'toCategoryTotal',
      label: `Total (${ toYear })`
    });

    let parsedData = _(sourceCategories)
      .map((sourceCategory) => {
        const rowTotal = _.sumBy(links, (link) => {
          if (link.source === sourceCategory) {
            return link.value;
          }
          return 0;
        });
        const categoryData = _.find(nodes, { id: sourceCategory });
        let rowData = { fromCategory: `${ _.get(categoryData, 'label') } (${ fromYear })` };

        _.each(targetCategories, (targetCategory) => {
          const categoryData = _.find(nodes, { id: targetCategory });
          const linkData = _.find(links, { source: sourceCategory, target: targetCategory });
          const value = _.get(linkData, 'value');

          rowData[_.kebabCase(`${ _.get(categoryData, 'label') } (${ toYear })`)] = value;
        });

        rowData['toCategoryTotal'] = rowTotal;

        return rowData;
      })
      .flatten()
      .value();

    let totalRow = {
      fromCategory: `Total (${ fromYear })`,
      toCategoryTotal: _.sumBy(links, 'value'),
    };

    _.each(targetCategories, (targetCategory) => {
      const categoryData = _.find(nodes, { id: targetCategory });
      const columnTotal = _.sumBy(links, (link) => {
        if (link.target === targetCategory) {
          return link.value;
        }
        return 0;
      });

      totalRow[_.kebabCase(`${ _.get(categoryData, 'label') } (${ toYear })`)] = columnTotal;
    });

    parsedData = _.concat(parsedData, totalRow);

    return (
      <CSVLink
        data={ parsedData }
        headers={ headers }
        className={ classnames(styles.csvDownloadLink, classes.printButton) }
        filename="MapBiomas - Tabela de Dados.csv"
      >
        <CloudDownloadIcon />
        <span>Download</span>
      </CSVLink>
    );
  };

  return (
    <div className={ styles.tableContainer } style={ { maxHeight: windowSize.height - 303 } }>
      <div className={ styles.tableHeader }>
        <h3 className={ styles.tableTitle }>
          <b><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.table.title" /></b>
          <span><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.table.subtitle" /></span>
        </h3>
        { renderDownloadTableLink() }
      </div>
      <div className={ styles.tableWrapper }>
        <div className={ styles.tableLeftTag }><span>{ fromYear }</span></div>
        <div className={ styles.tableTopTag }><span>{ toYear }</span></div>
        <div className={ styles.tableLeftCategoriesWrapper }>
          <ul className={ styles.tableCategoriesList }>
            { _.map(sourceCategories, (sourceCategory) => {
              const categoryData = _.find(nodes, { id: sourceCategory });

              return (
                <li key={ `source-item-${ sourceCategory }` }>{ _.get(categoryData, 'label') }</li>
              );
            }) }
            <li>Total</li>
          </ul>
        </div>
        <TableContainer>
          <Table size="small" className={ classnames(classes.table, styles.matrixTable) }>
            <TableHead>
              <TableRow>
                { _.map(targetCategories, (targetCategory) => {
                  const categoryData = _.find(nodes, { id: targetCategory });

                  return (
                    <TableCell align="right" key={ `target-${ targetCategory }` } className={ styles.categoryCell }>{ _.get(categoryData, 'label') }</TableCell>
                  );
                }) }
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { _.map(sourceCategories, (sourceCategory) => {
                const rowTotalByCategory = _.sumBy(links, (link) => {
                  if (link.source === sourceCategory) {
                    return link.value;
                  }
                  return 0;
                });

                return (
                  <TableRow key={ `source-${ sourceCategory }` }>
                    { _.map(targetCategories, (targetCategory) => {
                      const linkData = _.find(links, { source: sourceCategory, target: targetCategory });
                      const value = _.get(linkData, 'value');

                      return (
                        <TableCell
                          key={ `value-${ sourceCategory }-${ targetCategory }` }
                          align="right"
                        >
                          { formatValue(value) }
                        </TableCell>
                      );
                    }) }
                    <TableCell align="right">
                      { formatValue(rowTotalByCategory) }
                    </TableCell>
                  </TableRow>
                );
              }) }
              <TableRow>
                { _.map(targetCategories, (targetCategory) => {
                  const columnTotalByCategory = _.sumBy(links, (link) => {
                    if (link.target === targetCategory) {
                      return link.value;
                    }
                    return 0;
                  });

                  return (
                    <TableCell
                      align="right"
                      key={ `target-total-${ targetCategory }` }
                      className={ styles.categoryCell }
                    >
                      { formatValue(columnTotalByCategory) }
                    </TableCell>
                  );
                }) }
                <TableCell align="right">{ formatValue(_.sumBy(links, 'value')) }</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
