import React, { useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';

import useFormatMessage from '../../hooks/useFormatMessage';

import {
  GET_BASE_DATA,
} from './query';

import styles from './WarningNoteDialog.module.scss';

const availableLocaleNoteKeys = [
  'coverage_main',
  'coverage_changes',
  'coverage_quality_main',
  'quality_of_pasture_data_main',
  'irrigation_main',
  'trajectories_stable_areas',
  'deforestation_annual',
  'regeneration_annual',
  'fire_accumulated',
  'mining_main',
  'trajectories_number_of_classes',
  'irrigation_iframe',
  'soil_main',
  'sentinel_coverage'
];

export default function WarningNoteDialog() {
  const client = useApolloClient();
  const formatMessage = useFormatMessage();
  const { data: baseData } = useQuery(GET_BASE_DATA);
  const activeNoteKey = _.get(baseData, 'app.activeNoteKey');

  const [readNoteKeys, setReadNoteKeys] = useState([]);

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  const closeNoteDialog = () => {
    updateClientAppState({
      activeNoteKey: null,
    });
    setReadNoteKeys([...readNoteKeys, activeNoteKey]);
  };

  if (
    _.isNull(activeNoteKey) ||
    _.includes(readNoteKeys, activeNoteKey) ||
    !_.includes(availableLocaleNoteKeys, activeNoteKey)
  ) {
    return null;
  }

  return (
    <Dialog onClose={ closeNoteDialog } open={ !!activeNoteKey }>
      <div className={ styles.dialogWrapper }>
        <header className={ styles.header }>
          <h2 className={ styles.title }>
            <FormattedMessage id={ `mapbiomas.warning.${ activeNoteKey }.title` } />
          </h2>
          <IconButton className={ styles.closeButton } onClick={ closeNoteDialog }>
            <CloseIcon />
          </IconButton>
        </header>
        <div className={ styles.content }>
          <div
            className={ styles.contentDescription }
            dangerouslySetInnerHTML={{
              __html: formatMessage(`mapbiomas.warning.${ activeNoteKey }.body`)
            }}
          />
          <div className={ styles.actions }>
            <Button color="primary" variant="contained" onClick={ closeNoteDialog }>
              <FormattedMessage id={ `mapbiomas.warning.${ activeNoteKey }.button.confirm` } />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
