export default [
  {
    index: 0,
    key: "estados",
    labelKey: "states",
    color: "#222222",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_states-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IBGE' },
      { labelKey: 'year', label: 'Ano', value: '2018' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_states-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 1,
    key: "municipios",
    labelKey: "municipalities",
    color: "#fdbf6f",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_cities-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IBGE' },
      { labelKey: 'year', label: 'Ano', value: '2018' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_cities-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 2,
    key: "bioma",
    labelKey: "biomes",
    color: "#eb2f06",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_biomes-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'IBGE' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_biomes-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 3,
    key: "amazonia_legal",
    labelKey: "legal_amazon",
    color: "#947438",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_amazonia-legal-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'INPE - Terrabrasilis' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_amazonia-legal-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 4,
    key: "regioes-hidrograficas",
    labelKey: "watersheds_regions",
    color: "#2980B9",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/wms",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_regioes-hidrograficas-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ANA' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas:dashboard_regioes-hidrograficas-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 5,
    key: "baciasn2",
    labelKey: "watersheds",
    color: "#55f8f8",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_river-basins-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'ANA' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_river-basins-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 6,
    key: "ucs",
    labelKey: "conservation_units",
    color: "#fc795b",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_conservation-unit-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MMA' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_conservation-unit-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 7,
    key: "tis",
    labelKey: "indigenous_lands",
    color: "#6a3d9a",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_indigenous-lands-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Funai' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_indigenous-lands-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 8,
    key: "assentamentos",
    labelKey: "settlements",
    color: "#730e21",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_settlements-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Incra' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_settlements-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 9,
    key: "quilombos",
    labelKey: "quilombos",
    color: "#7f6b83",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:dashboard_quilombo-static-layer"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Incra' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_quilombo-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 10,
    key: "car",
    labelKey: "car_complete_layer",
    color: "#636363",
    props: {
      url: 'https://shared-geoserver.mapbiomas.org/geoserver/gwc/service/wmts?layer=mapbiomas-alerta%3Amv_sfb_car&style=&tilematrixset=EPSG%3A3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG%3A3857%3A{z}&TileCol={x}&TileRow={y}',
      transparent: true,
      format: 'image/png',
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Serviço Florestal Brasileiro' },
      { labelKey: 'year', label: 'Ano', value: '2022' },
    ],
    // downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Adashboard_rural-properties-static-layer&outputFormat=SHAPE-ZIP'
  },
  {
    index: 11,
    key: "todas_camadas",
    labelKey: "biosphere",
    color: "#222222",
    props: {
      url: "https://production.alerta.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-alertas:v_layers_biosphere_reserve"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'MMA' },
      { labelKey: 'year', label: 'Ano', value: '2019' },
    ],
    downloadUrl: 'https://production.alerta.mapbiomas.org/geoserver/mapbiomas-alertas/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=mapbiomas-alertas%3Av_layers_biosphere_reserve&outputFormat=SHAPE-ZIP'
  },
  // {
  //   index: 11,
  //   key: "intersecting-cars",
  //   labelKey: "car_alerts_intersection",
  //   color: "#3562cb",
  //   props: {
  //     url: "https://production.alerta.mapbiomas.org/geoserver/ows",
  //     transparent: true,
  //     format: "image/png",
  //     layers: "mapbiomas-alertas:v_rural_properties"
  //   }
  // },
  // {
  //   index: 11,
  //   key: "embargos",
  //   labelKey: "embargoed_areas",
  //   color: "#e400ff",
  //   props: {
  //     url: "https://production.alerta.mapbiomas.org/geoserver/ows",
  //     transparent: true,
  //     format: "image/png",
  //     layers: "mapbiomas-alertas:v_embargoed_areas"
  //   }
  // },
];
