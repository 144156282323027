import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import PieChart from '../../components/PieChart';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

export default function StableAreasStatisticsContent({
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,
}) {
  const locale = localStorage.getItem('locale') || 'pt-BR';

  const yearFormatted = Number(_.join(activeYear, ''));
  const years = [yearFormatted];
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    }
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');

  const { data: baseData, loading, error: errorBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      classTreeNodeIds: activeClassTreeNodeIds,
      years: years,
      territoriesIds: territoryIds
    },
    skip: loadingClassTreeData
  });

  const getTotalAreaByClassesData = () => {
    const data = _.get(baseData, 'getCalcAcumCoverageByClasses');

    const parsedSeries = _.map(_.get(_.first(data), 'acumYear'), (item) => {
      const { classTreeNodeId, total } = item;

      const nodeData = _.find(classTreeLevelsList, { id: classTreeNodeId });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');
      const prettyIndexOfNodeInTree = _.join(_.get(nodeData, 'positionInTree'), '.');

      return {
        nodeId: classTreeNodeId,
        name: `${ prettyIndexOfNodeInTree }. ${ label }`,
        color: _.get(nodeData, 'color') || '#e74c3c',
        total
      };
    });

    const sortedSeries = _.sortBy(parsedSeries, 'name');

    return {
      series: sortedSeries,
    };
  };

  useEffect(() => {
    if (!loading && errorBaseData) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && baseData) {
      setChartsData({
        'totalAreaByClasses': getTotalAreaByClassesData(),
      });
    }
  }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const numberOfClasses = () => {
    const series = _.get(chartsData, 'totalAreaByClasses.series') || [];

    const parsedSeries = _.map(series, (serie) => {
      return {
        name: serie.name,
        value: serie.total,
        itemStyle: {
          color: serie.color
        },
      }
    });

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>Visualização por classe ({ _.join(activeYear, ' - ') })</h2>
        <PieChart
          serie={{
            title: 'Total',
            data: parsedSeries
          }}
        />
      </div>
    );
  };

  const handleShowData = () => {
    const totalAreaByClassesChartData = _.get(chartsData, 'totalAreaByClasses');
    const totalAreaByClassesChartDataCategories = _.get(totalAreaByClassesChartData, 'categories') || [];
    const totalAreaByClassesChartDataSeries = _.get(totalAreaByClassesChartData, 'series') || [];

    const totalAreaByClassesChart = {
      title: <FormattedMessage id="mapbiomas.number_of_classes_content.bar_chart_title" />,
      columns: totalAreaByClassesChartDataCategories,
      rows: totalAreaByClassesChartDataSeries
    };

    onShowData([
      totalAreaByClassesChart,
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { numberOfClasses() }
        {/* <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div> */}
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p>Nenhum resultado encontrado.</p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
