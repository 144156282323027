import { gql } from 'graphql.macro';

export const GET_CLASS_TREE_DATA = gql`
  query GetClassTreeData(
    $classTreeKey: String!,
  ) {
    classTreeByKey(key: $classTreeKey) {
      id
      key
     	i18nStrings {
				language
				stringValue
      }
			mvClassTreeLevelsUi {
        id
        color
        classId
        childrenIds
        positionInTree
        level
        parentId
        defaultName
        classTreeId
				i18nStrings {
					language
					stringValue
				}
      }
    }
  }
`;


export const GET_BASE_DATA = gql`
  query GetBaseData(
    $classTreeNodeIds: [Int!]!,
    $toYear: Int!,
    $territoriesIds: [Int!]!,
  ) {
    frequencyAccumulatedGrouped(
      fromYear: 1985,
      toYear: $toYear,
      classTreeNodeIds: $classTreeNodeIds,
      territoriesIds: $territoriesIds,
    ) {
      year
      areaInHa
    }
  }
`;
