import React, { useEffect } from 'react'
import _ from 'lodash'
import { useApolloClient, useQuery } from '@apollo/react-hooks'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import ClassTreesControl from './components/ClassTreesControl'
import ClassLegendList from './components/ClassLegendList'
import { updateStateCache as uncurriedUpdateStateCache } from '../../../../../../utils/apollo'

import customClient from '../../../../../../core/custom-apollo-client/custom-apollo-client';

import {
  GET_BASE_DATA,
  GET_CLASSES_LEVELS_LIST,
  GET_CLASS_TREE_DATA,
} from './query'

import styles from './GenericClasses.module.scss'

const mapClassTreeKeyToDefaultLevels = {
  'deforestation_annual_data': [1, 2],
  'regeneration_annual_data': [1, 2],
  'regeneration_accumulated_data': [1, 2],
  'mining_annual': [1],
}

export default function GenericClasses({
  clientType,
  hideLegendIndex,
  moduleId,
  submoduleKey,
  hideLevelsControl,
}) {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const locale = localStorage.getItem('locale') || 'pt-BR'
  const client = useApolloClient()
  const updateStateCache = _.partial(uncurriedUpdateStateCache, client)
  const { data: baseData } = useQuery(GET_BASE_DATA)
  const { data: classesTreesData } = useQuery(GET_CLASSES_LEVELS_LIST, {
    variables: {
      moduleId,
      submoduleKey
    },
    client: clientType === 'custom' ? customClient : undefined
  })

  const activeClassTreeNodeIds = _.get(baseData, 'app.baseParams.activeClassTreeNodeIds')

  const isOnInitialRenderingWithQueryParams = !_.isEmpty(activeClassTreeNodeIds) &&
    _.isUndefined(classesTreesData);

  const classTrees = _.get(classesTreesData, 'submoduleByModuleIdAndKey[0].submoduleTrees')
  const activeClassTreeOptionValue = _.get(baseData, 'app.baseParams.activeClassTreeOptionValue')

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey: activeClassTreeOptionValue
    },
    skip: !activeClassTreeOptionValue
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi') || [];

  useEffect(() => {
    if (isOnInitialRenderingWithQueryParams) {
      return;
    }

    const activeTreeIsInCurrentSubmoduleTrees = _.some(
      classTrees,
      { classTree: { key: activeClassTreeOptionValue }}
    )
    if (baseData && classesTreesData && (!activeClassTreeOptionValue || !activeTreeIsInCurrentSubmoduleTrees)) {
      const firstTree = _.get(classTrees, '[0].classTree')
      const firstTreeKey = _.get(firstTree, 'key')

      updateStateCache('app.baseParams',
        [ 'activeClassTreeOptionValue', firstTreeKey ],
        [ 'activeSubmodule', submoduleKey ]
      )
    }
  }, [baseData, classesTreesData, activeClassTreeOptionValue, submoduleKey]);

  useEffect(() => {
    if (isOnInitialRenderingWithQueryParams) {
      return;
    }

    if (baseData && classTreeData && activeClassTreeOptionValue) {
      let nodeIds = _.map(classTreeLevelsList, 'id');

      if (mapClassTreeKeyToDefaultLevels[activeClassTreeOptionValue] || submoduleKey === 'infrastructure_main') {
        let defaultLevels = mapClassTreeKeyToDefaultLevels[activeClassTreeOptionValue];

        if (submoduleKey === 'infrastructure_main') {
          defaultLevels = [1, 2];
        }

        nodeIds = _.chain(classTreeLevelsList)
          .filter((node) => _.includes(defaultLevels, _.get(node, 'level')))
          .map('id')
          .value();
      } else if (submoduleKey === 'regeneration_annual') {
        nodeIds = _.chain(classTreeLevelsList)
          .filter(({ positionInTree }) => _.first(positionInTree) !== 1)
          .map('id')
          .value();
      }

      updateStateCache('app.baseParams',
        [ 'activeClassTreeNodeIds', nodeIds ],
      );
    }
  }, [classTreeData, activeClassTreeOptionValue]);

  if (!baseData || !classesTreesData) {
    return null
  }

  if (!activeClassTreeOptionValue) {
    return null
  }

  const activeClassTree = _.find(
    classTrees, { classTree: { key: activeClassTreeOptionValue } }
  )
  const nodesOfActiveClassTree = classTreeLevelsList;

  const levels = _.sortBy(_.uniq(_.map(nodesOfActiveClassTree, 'level')))
  const activeLevels = _.filter(levels, (levelId) => {
    const idsByLevel = _.map(_.filter(nodesOfActiveClassTree, { level: levelId }), 'id')
    const idsByLevelActive = _(idsByLevel)
      .filter((id) => _.includes(activeClassTreeNodeIds, id))
      .compact()
      .value();

    return _.size(idsByLevelActive) === _.size(idsByLevel);
  })

  const handleActiveTreeOptionChange = (classTreeOption) => {
    const classTreeKey = classTreeOption.value
    const activeClassTree = _.find(
      classTrees, { classTree: { key: classTreeKey } }
    )
    const nodesOfActiveClassTree = classTreeLevelsList;
    let nodeIds = _.map(nodesOfActiveClassTree, 'id')

    if (mapClassTreeKeyToDefaultLevels[classTreeKey]) {
      const defaultLevels = mapClassTreeKeyToDefaultLevels[classTreeKey]
      nodeIds = _.chain(nodesOfActiveClassTree)
        .filter((node) => _.includes(defaultLevels, _.get(node, 'level')))
        .map('id')
        .value()
    }

    updateStateCache(
      'app.baseParams',
      [ 'activeClassTreeOptionValue', classTreeKey ],
      [ 'activeClassTreeNodeIds', nodeIds ]
    )
  }

  const handleClassTreeNodeToggle = (classTreeNode) => {
    const nodeId = _.get(classTreeNode, 'id')

    if (_.includes(activeClassTreeNodeIds, nodeId)) {
      updateStateCache('app.baseParams', [
        'activeClassTreeNodeIds',
        _.without(activeClassTreeNodeIds, nodeId)
      ])
    } else {
      updateStateCache('app.baseParams', [
        'activeClassTreeNodeIds',
        [ ...activeClassTreeNodeIds, nodeId ]
      ])
    }
  }

  const handleLevelToggle = (levelId) => {
    const idsByLevel = _.map(_.filter(nodesOfActiveClassTree, { level: levelId }), 'id')

    if (_.includes(activeLevels, levelId)) {
      let idsClone = _.clone(activeClassTreeNodeIds);
      idsClone = _.filter(idsClone, (id) => !_.includes(idsByLevel, id))

      updateStateCache('app.baseParams', [
        'activeClassTreeNodeIds',
        idsClone
      ])
    } else {
      const nextIds = _.uniq(_.concat(activeClassTreeNodeIds, idsByLevel))

      updateStateCache('app.baseParams', [
        'activeClassTreeNodeIds',
        nextIds
      ])
    }
  }

  const handleClassTreeNodeShowTooltip = (classTreeNode) => {
    if (isMobile) {
      updateStateCache('app',
        [ 'headerIsVisible', false ],
        [ 'showClassInfo', classTreeNode.id ]
      )
    } else {
      updateStateCache('app',
        [ 'showClassInfo', classTreeNode.id ]
      )
    }
  }

  const classesControlTreeOptions = _.map(classTrees, (classTree) => ({
    label: _.chain(classTree)
      .get('classTree.i18nStrings')
      .find({ language: locale })
      .get('stringValue')
      .value(),
    value: _.get(classTree, 'classTree.key')
  }))

  return (
    <div className={ styles.wrapper }>
      <ClassTreesControl
        classTreeOptions={ classesControlTreeOptions }
        activeTreeOption={
          _.find(
            classesControlTreeOptions,
            { value: activeClassTreeOptionValue }
          )
        }
        levels={ levels }
        activeLevels={ activeLevels }
        submoduleKey={ submoduleKey }
        onActiveTreeOptionChange={ handleActiveTreeOptionChange }
        onLevelToggle={ handleLevelToggle }
        hideLevelsControl={ hideLevelsControl }
      />
      <ClassLegendList
        hideLegendIndex={ hideLegendIndex }
        classTreeNodes={ nodesOfActiveClassTree }
        activeClassTreeNodeIds={ activeClassTreeNodeIds }
        onClassTreeNodeToggle={ handleClassTreeNodeToggle }
        onClassTreeNodeShowTooltip={ handleClassTreeNodeShowTooltip }
      />
    </div>
  )
}
