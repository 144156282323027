import React, { useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { useIntl, FormattedMessage } from 'react-intl';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import AnnualStatisticsContent from './content/AnnualStatisticsContent';
import AccumulatedStatisticsContent from './content/AccumulatedStatisticsContent';
import AccumulatedDistributedStatisticsContent from './content/AccumulatedDistributedStatisticsContent';
import CoverageMainStatisticsContent from './content/CoverageMainStatisticsContent';
import FireAnnualTotalBurnedStatisticsContent from './content/FireAnnualTotalBurnedStatisticsContent';
import FireMonthlyStatisticsContent from './content/FireMonthlyStatisticsContent';
import FireAccumulatedStatisticsContent from './content/FireAccumulatedStatisticsContent';
import FrequencyStatisticsContent from './content/FrequencyStatisticsContent';
import NumberOfClassesStatisticsContent from './content/NumberOfClassesStatisticsContent';
import StableAreasStatisticsContent from './content/StableAreasStatisticsContent';

import InfoTooltip from '../../../../../../components/InfoTooltip';

import styles from './StatisticsBox.module.scss';

export default function StatisticsBox({
  activeModuleContent,
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData = () => {},
  headerIsVisible,
}) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const intl = useIntl()

const mapClassTreeKeyToTooltip = {
  'fire_annual_total_burned': intl.formatMessage({id: 'mapbiomas.statistics.burned.annual_total.charts.tooltip'}),
  'fire_monthly': intl.formatMessage({id: 'mapbiomas.statistics.burned.monthly.charts.tooltip'}),
  'fire_frequency': intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.charts.tooltip'}),
  'fire_accumulated': intl.formatMessage({id: 'mapbiomas.statistics.burned.accumulated.charts.tooltip'}),
};

  const defaultContentParams = {
    activeYear,
    baseParams,
    classTreeKey,
    statisticsDataIsVisible,
    onShowData
  };
  const titleTooltip = mapClassTreeKeyToTooltip[classTreeKey];

  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <div id="statistics" className={ classnames(styles.wrapper, {
      [styles.wrapperWithHeader]: headerIsVisible,
      [styles.wrapperIsHidden]: isMobile,
    }) }>
      <header className={ styles.header }>
        <h2 className={ styles.title }>
          <span><FormattedMessage id="mapbiomas.statistics.title" /></span>
          { titleTooltip && <InfoTooltip text={ titleTooltip } /> }
        </h2>
        <IconButton
          className={ classnames(styles.closeButton, {
            [styles.closeButtonCollapse]: isVisible
          }) }
          onClick={ toggleVisibility }
          id="statistics-toggle"
        >
          <ExpandLessIcon />
        </IconButton>
      </header>
      <Collapse id="statistics-graphics" in={ isVisible } timeout={ 500 } className={ styles.collapseWrapper }>
        <div className={ styles.content }>
          { activeModuleContent === 'sentinel:sentinel_coverage' &&
            <div className={ styles.fireModeContentBoxExtra }>
              <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.warning.sentinel_coverage.extra_note.title" /></h2>
              <div
                className={ styles.fireModeContentBoxTextWrapper }
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'mapbiomas.warning.sentinel_coverage.extra_note.content'})
                }}
              />
            </div>
          }

          { _.includes(activeModuleContent, 'mining') && <AnnualStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'quality_of_pasture_data') && <AnnualStatisticsContent clientType="custom" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'irrigation') && <AnnualStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'trajectories') && classTreeKey !== 'number_of_classes_main' && <StableAreasStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'trajectories') && classTreeKey === 'number_of_classes_main' && <NumberOfClassesStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'deforestation') && classTreeKey === 'deforestation_annual_by_class' && <AnnualStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'deforestation') && classTreeKey === 'deforestation_accumulated_by_class' && <AccumulatedDistributedStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'deforestation') && classTreeKey === 'deforestation_frequency_main' && <FrequencyStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'regeneration') && classTreeKey === 'regeneration_annual_by_class' && <AnnualStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'regeneration') && classTreeKey === 'regeneration_accumulated_by_class' && <AccumulatedStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'regeneration') && classTreeKey === 'regeneration_age_main' && <FrequencyStatisticsContent ageMode { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_monthly' && <FireMonthlyStatisticsContent clientType="custom" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_frequency' && <FrequencyStatisticsContent fireMode clientType="custom" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_accumulated_by_total_burned' && <FireAccumulatedStatisticsContent clientType="custom" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_accumulated_by_class' && <AccumulatedDistributedStatisticsContent clientType="custom" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_accumulated_by_natural_anthropic' && <AccumulatedDistributedStatisticsContent clientType="custom" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_annual_by_total_burned' && <FireAnnualTotalBurnedStatisticsContent clientType="custom" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_annual_by_class' && <CoverageMainStatisticsContent chartType="bar" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'fire') && classTreeKey === 'fire_annual_by_natural_anthropic' && <CoverageMainStatisticsContent chartType="bar" { ...defaultContentParams } /> }
          { activeModuleContent === 'coverage:coverage_main' && <CoverageMainStatisticsContent { ...defaultContentParams } /> }
          { activeModuleContent === 'sentinel:sentinel_coverage' && <CoverageMainStatisticsContent { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'soil') && classTreeKey === 'soil_total_carbon' && <CoverageMainStatisticsContent hasSingleHistoricalData hasCustomCOSValues valueMantissa={ 1 } { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'soil') && classTreeKey === 'soil_by_class' && <CoverageMainStatisticsContent unit="GtCOS" valueMantissa={ 1 } chartType="bar" { ...defaultContentParams } /> }
          { _.includes(activeModuleContent, 'soil') && classTreeKey === 'soil_natural_or_anthropic' && <CoverageMainStatisticsContent unit="GtCOS" valueMantissa={ 1 } { ...defaultContentParams } /> }
        </div>
      </Collapse>
    </div>
  );
}
