export default {
  'territorial': [
    'country',
    'biome',
    'state',
    'city',
    'level_1_basin_pnhr',
    'level_2_basin_pnhr',
    'special_territories',
    'biosphere_reserve',
    'protected_areas',
    'geoparks',
  ],
  'land': [
    'settlement',
    'car',
    'quilombo',
    'indigenous_land',
    'indigenous_land_new',
    'state_conservation_units_integral_protection',
    'state_conservation_units_sustainable_use',
    'federal_conservation_units_integral_protection',
    'federal_conservation_units_sustainable_use',
  ]
};
